<template>
    <div class="arrow-navigation">
        <img
                class="arrow left"
                :class="{distance: !hasNext}"
                v-if="hasPrevious"
                src="../assets/tipp/tipp-arrow.svg"
                @click="$emit('previous-clicked')">
        <img
                class="arrow right"
                v-if="hasNext"
                src="../assets/tipp/tipp-arrow.svg"
                @click="$emit('next-clicked')">
    </div>
</template>

<script>
    export default {
        name: "TippArrowNavigation",
        props: {
            hasPrevious: {
                type: Boolean,
                default: true
            },
            hasNext: {
                type: Boolean,
                default: true
            },
        },
    }
</script>

<style lang="scss" scoped>

    .arrow-navigation {
        height: 80px;
        position: absolute;

        @include mobile-down {
            height: 50px;
        }
    }

    .arrow {
        width: 70px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        @include mobile-down {
            width: 40px;
        }
    }

    .left {
        transform: rotate(180deg);
        margin-right: 10px;

        &.distance {
            position: absolute;
            right: 70px;
        }

        &:hover {
            transform: rotate(180deg) translate(10%);
        }
    }

    .right {
        &:hover {
            transform: translate(10%);
        }
    }
</style>