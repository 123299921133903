import { render, staticRenderFns } from "./Grundlagen.vue?vue&type=template&id=63ec50df&scoped=true"
import script from "./Grundlagen.vue?vue&type=script&lang=js"
export * from "./Grundlagen.vue?vue&type=script&lang=js"
import style0 from "./Grundlagen.vue?vue&type=style&index=0&id=63ec50df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ec50df",
  null
  
)

export default component.exports