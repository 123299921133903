<template>
  <div class="grundlagen">
    <img class="close-grundlagen" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
    <div class="textsheet" v-if="linkData">
      <h2>{{$t('grundlagen.titel')}}</h2>
      <h3>{{linkData[pageIndex].title }}</h3>
      <p>
        <br>
        <span v-for="link in linkData[pageIndex].links" :key="link.link">
          <a :href="'/grundlagen/' + link.url" @click.prevent="nextPage(Number(link.url))" v-if="link.internal">{{ link.link }}</a>
          <a :href="link.url" target="_blank" v-else>{{ link.link }}</a><br><br><br>
        </span>
      </p>
      <TippArrowNavigation
          class="tipp-navigation"
          :has-previous="!isFront"
          :has-next="!isEnd"
          @previous-clicked="previousPage()"
          @next-clicked="nextPage()"></TippArrowNavigation>
    </div>
  </div>
</template>

<script>
import TippArrowNavigation from "@/components/TippArrowNavigation";
import data from "@/data/data-tree.js";

export default {
  name: "grundlagen",
  data() {
    return {
      isFront: true,
      isEnd: false,
      pageIndex: 0,
      linkData: undefined
    }
  },
  mounted() {
    this.linkData = data['grundlagen' + this.$i18n.locale]
    if(this.$route.params.page) {
      this.pageIndex = Number(this.$route.params.page)
    }
  },
  components: {
    TippArrowNavigation
  },
  watch: {
    pageIndex: function () {
      console.log(this.pageIndex)
    }
  },
  methods: {
    nextPage(param = undefined) {
      if (!this.isEnd) {
        if (param === undefined) {
          this.pageIndex++;
        } else {
          this.pageIndex = param
          console.log(param)
        }
      }

      if (this.pageIndex >= 1) {
        this.isFront = false;
      }

      if ((this.pageIndex + 1) >= this.linkData.length) {
        this.isEnd = true
      }
    },
    previousPage() {
      if (!this.isFront) {
        this.pageIndex--;
      }

      if (this.pageIndex == 0) {
        this.isFront = true;
      }

      if (this.pageIndex <= this.linkData.length) {
        this.isEnd = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.grundlagen {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 70px;

  @include mobile-down {
    padding: 25px;
  }
}

.close-grundlagen {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include tablet-down {
    width: 35px;
    z-index: 5;
  }
}

.tipp-navigation {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

</style>